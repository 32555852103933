import React from "react"
import Container from "react-bootstrap/Container"
import Dot from "../../images/IOS/closeDot1.png"
import rec from "../../images/IOS/rec.png"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import circle from "../../images/IOS/circle.png"
import cir from "../../images/IOS/cir.png"
import arrow2 from "../../images/IOS/arrow2.png"
import * as styles from "./BuildingSoftware.module.scss"

const BuildingSoftware = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)
  return (
    <div className={styles.Ideation}>
      <img
        src={Dot}
        className={styles.dots}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={rec}
        className={styles.rec}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={circle}
        className={styles.circle}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.dec}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.cardsData}>
          <div className={styles.leftText}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i < 3 && (
                    <div className={styles.cards} key={i}>
                      <img
                        src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                        alt={"numbers"}
                        decoding="async"
                        loading="lazy"
                        className={styles.number}
                      />
                      <div
                        className={styles.step}
                        dangerouslySetInnerHTML={{
                          __html: e?.description?.description,
                        }}
                      />
                      <h3>{e?.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
          <div className={styles.closeUp}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                decoding="async"
                loading="lazy"
              />
            )}
          </div>
          <div className={styles.rightText}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i > 2 && (
                    <div className={styles.cards2} key={i}>
                      <img
                        src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                        alt={"numbers"}
                        decoding="async"
                        loading="lazy"
                        className={styles.number2}
                      />
                      <div
                        className={styles.step}
                        dangerouslySetInnerHTML={{
                          __html: e?.description?.description,
                        }}
                      />
                      <h3>{e?.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
        <img
          src={Dot}
          className={styles.dots2}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
        <img
          src={cir}
          className={styles.cir}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
        <img
          src={arrow2}
          className={styles.arrow2}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
      </Container>
    </div>
  )
}

export default BuildingSoftware
