// extracted by mini-css-extract-plugin
export var arrow2 = "DynamicProject-module--arrow2--66909";
export var btn_white1_border = "DynamicProject-module--btn_white1_border--03f55";
export var chooseDots = "DynamicProject-module--chooseDots--077e4";
export var cir = "DynamicProject-module--cir--f1dd9";
export var circle = "DynamicProject-module--circle--e2785";
export var col1 = "DynamicProject-module--col1--cef7e";
export var col2 = "DynamicProject-module--col2--e34e0";
export var dots = "DynamicProject-module--dots--aefb1";
export var dots2 = "DynamicProject-module--dots2--beaaa";
export var for1 = "DynamicProject-module--for1--d5ef9";
export var hireJS = "DynamicProject-module--hireJS--0bf25";
export var hireJSBtn = "DynamicProject-module--hireJSBtn--66f3b";
export var hireJSHeading = "DynamicProject-module--hireJSHeading--63b72";
export var jsCards = "DynamicProject-module--jsCards--a852b";
export var rec = "DynamicProject-module--rec--e55fa";
export var subTitle = "DynamicProject-module--subTitle--01796";