import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import arrow from "../../images/android-App-Development/arrow.svg"
import check from "../../images/javascript/checkMark.svg"
import "./js.scss"
import * as styles from "./OurIndustry.module.scss"

const OurIndustry = ({ strapiData }) => {
  return (
    <div className={`${styles.tabs} cardTabingJs`}>
      <Container>
        <div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Fintech">
            <Row>
              <Col lg={6}>
                <h2
                  className={styles.heading}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                ></h2>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
                <Nav variant="pills" className={`navbarJs`}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div className={styles.tabImg}>
                          <Nav.Link eventKey={`${e?.title}`}>
                            <img
                              className={styles.finImg}
                              src={check}
                              alt="icon"
                            />
                            {e?.title}
                          </Nav.Link>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </Col>
              <Col lg={6}>
                <div className="tabs">
                  <Tab.Content className="contentBlockJs">
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Tab.Pane eventKey={`${e?.title}`} key={i}>
                          <div className={styles.boxContent}>
                            <div>
                              <img
                                className={`${styles.finImg2} pt-4`}
                                src={
                                  e?.image1 &&
                                  e?.image1[0]?.localFile?.publicURL
                                }
                                alt={e?.image1 && e?.image1[0]?.alternativeText}
                              />
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              ></p>
                            </div>
                            <div className={styles.estabBtn}>
                              {e?.buttons[0]?.title && (
                                <Link
                                  to={e?.buttons[0]?.url}
                                  variant="light "
                                  className={styles.grabbing}
                                >
                                  {e?.buttons[0]?.title}
                                  <img
                                    className={styles.arrowImg}
                                    src={arrow}
                                    decoding="async"
                                    loading="lazy"
                                    alt="arrow"
                                  />
                                </Link>
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                      ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default OurIndustry
