// extracted by mini-css-extract-plugin
export var arrowImg = "OurIndustry-module--arrowImg--72deb";
export var boxContent = "OurIndustry-module--boxContent--b850e";
export var cards = "OurIndustry-module--cards--17c07";
export var cardsContent = "OurIndustry-module--cardsContent--afca9";
export var cardsZ = "OurIndustry-module--cardsZ--4637a";
export var description = "OurIndustry-module--description--81841";
export var estabBtn = "OurIndustry-module--estabBtn--ace01";
export var explorrBtn = "OurIndustry-module--explorrBtn--ddcdf";
export var finImg = "OurIndustry-module--finImg--9552e";
export var finImg2 = "OurIndustry-module--finImg2--c833d";
export var grabbing = "OurIndustry-module--grabbing--cc8d0";
export var heading = "OurIndustry-module--heading--6be1e";
export var tabImg = "OurIndustry-module--tabImg--cba26";
export var tabbingCards = "OurIndustry-module--tabbingCards--987a0";
export var tabs = "OurIndustry-module--tabs--2c081";