// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--b3eb6";
export var clientDots2 = "Testimonials-module--clientDots2--76a24";
export var content = "Testimonials-module--content--1da0f";
export var dots3 = "Testimonials-module--dots3--0e554";
export var hireArrow = "Testimonials-module--hireArrow--cc81d";
export var iconContainer = "Testimonials-module--iconContainer--2897e";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--a76fd";
export var imgBtn = "Testimonials-module--imgBtn--6b9e4";
export var person = "Testimonials-module--person--b7488";
export var personBg = "Testimonials-module--personBg--badd6";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--db292";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--b7bab";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--602b4";
export var row = "Testimonials-module--row--aafd9";
export var section = "Testimonials-module--section--83f12";
export var staffRec = "Testimonials-module--staffRec--1934e";
export var testDec = "Testimonials-module--testDec--a1b3c";
export var testName = "Testimonials-module--testName--fbf84";
export var testimonials = "Testimonials-module--testimonials--529f1";
export var testimonialsContent = "Testimonials-module--testimonialsContent--6faba";
export var vector2 = "Testimonials-module--vector2--f96fe";