// extracted by mini-css-extract-plugin
export var SliderWidth = "OurIndustry2-module--SliderWidth--2d97c";
export var arrowImg = "OurIndustry2-module--arrowImg--ceffc";
export var card = "OurIndustry2-module--card--ee02f";
export var cardWrapper = "OurIndustry2-module--cardWrapper--8b01d";
export var cards = "OurIndustry2-module--cards--5b5aa";
export var description = "OurIndustry2-module--description--201bb";
export var fineBg = "OurIndustry2-module--fineBg--42707";
export var grabbing = "OurIndustry2-module--grabbing--5227f";
export var heading = "OurIndustry2-module--heading--aeddc";
export var headingPremium = "OurIndustry2-module--headingPremium--f53cf";
export var iconContainer = "OurIndustry2-module--iconContainer--fc8e7";
export var iconContainerLeft = "OurIndustry2-module--iconContainerLeft--6e811";
export var portfolioArrowIcon = "OurIndustry2-module--portfolioArrowIcon--61918";
export var portfolioArrowIconCover = "OurIndustry2-module--portfolioArrowIconCover--ce7d7";
export var portfolioArrowRightIconCover = "OurIndustry2-module--portfolioArrowRightIconCover--d15f4";
export var premiumBg = "OurIndustry2-module--premiumBg--943d9";
export var premiumImg = "OurIndustry2-module--premiumImg--7f724";
export var providingImg = "OurIndustry2-module--providingImg--2f4e5";
export var teamButton = "OurIndustry2-module--teamButton--ccae2";
export var teamWork = "OurIndustry2-module--teamWork--80eb5";
export var trailBg = "OurIndustry2-module--trailBg--b643e";