import React from "react"
import Col from "react-bootstrap/esm/Col"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import checkMark from "../../images/javascript/checkMark.svg"
import hireJS from "../../images/javascript/hire-javascript-developers .svg"
import for1 from "../../images/it-staff/for.svg"
import chooseDots from "../../images/javascript/chooseDots.svg"
import rec from "../../images/IOS/rec.png"
import Dot from "../../images/IOS/closeDot1.png"
import circle from "../../images/IOS/circle.png"
import cir from "../../images/IOS/cir.png"
import arrow2 from "../../images/IOS/arrow2.png"
import * as styles from "./DynamicProject.module.scss"
import { Link } from "gatsby"

const DynamicProject = ({ strapiData }) => {
  return (
    <div className={styles.hireJS}>
      <img
        className={styles.for1}
        src={for1}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.chooseDots}
        src={chooseDots}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        src={rec}
        className={styles.rec}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={circle}
        className={styles.circle}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={Dot}
        className={styles.dots}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={Dot}
        className={styles.dots2}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <h2
              className={styles.hireJSHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <p className={styles.subTitle}>{strapiData?.subTitle}</p>
            {strapiData?.cards?.map((val, i) => (
              <div className={styles.jsCards} key={i}>
                <img className={styles.col1} src={checkMark} alt="check mark" />
                <div className={styles.col2}>
                  <h3>{val?.title}</h3>
                  <p>{val?.subTitle}</p>
                </div>
              </div>
            ))}
            <div className={`${styles.hireJSBtn}`}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className={styles.btn_white1_border}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col lg={6} md={12}>
            <img src={hireJS} alt="hire-javascript-developers" />
          </Col>
        </Row>
        <img
          src={cir}
          className={styles.cir}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
        <img
          src={arrow2}
          className={styles.arrow2}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
      </Container>
    </div>
  )
}

export default DynamicProject
