import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Testimonials2 from "../components/healthcare/Testimonials2"
import Banner from "../components/javascript/Banner"
import BuildingSoftware from "../components/javascript/BuildingSoftware"
import BuildingSoftware2 from "../components/javascript/BuildingSoftware2"
import ChooseUs from "../components/javascript/ChooseUs"
import DynamicProject from "../components/javascript/DynamicProject"
import GotProject from "../components/javascript/GotProject"
import OurIndustry from "../components/javascript/OurIndustry"
import OurIndustry2 from "../components/javascript/OurIndustry2"
import PowerUp from "../components/javascript/PowerUp"
import TechnologiesUse from "../components/javascript/TechnologiesUse"
import Testimonials from "../components/javascript/Testimonials"
import Unlock from "../components/javascript/Unlock"
import WorkPortfolio from "../components/common/WorkPortfolio"
import MainLayout from "../layouts/MainLayout"
import "../components/javascript/javascript.scss"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/javascript_development_company_b5b513380e.svg"
    />
  )
}
const javascript = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const power = data?.strapiPage?.sections[1]
  const industry = data?.strapiPage?.sections[2]
  const building = data?.strapiPage?.sections[3]
  const unlock = data?.strapiPage?.sections[4]
  const choose = data?.strapiPage?.sections[5]
  const dynamic = data?.strapiPage?.sections[6]
  const form = data?.strapiPage?.sections[7]
  const collaborate = data?.strapiPage?.sections[8]
  const testimonials = data?.strapiPage?.sections[9]
  const tech = data?.strapiPage?.sections[10]
  const faqs = data?.strapiPage?.sections[11]
  const building2 = data?.strapiPage?.sections[12]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} />
      <PowerUp strapiData={power} />
      {!isMobile ? (
        <OurIndustry strapiData={industry} />
      ) : (
        <OurIndustry2 strapiData={industry} />
      )}
      {!isMobile && !isTablet ? (
        <BuildingSoftware strapiData={building} />
      ) : (
        <BuildingSoftware2 strapiData={building2} />
      )}
      <Unlock strapiData={unlock} />
      <ChooseUs strapiData={choose} />
      <DynamicProject strapiData={dynamic} />
      <GotProject strapiData={form} pageName="Javascript Development Company" />
      <div className="paddingTopEngagement">
        <EngagementModels strapiData={collaborate} />
      </div>
      <TechnologiesUse strapiData={tech} />
      <WorkPortfolio
        portfolioHead={"Our Portfolio, Your Inspiration"}
        portfolioDesc={
          "From Concept to Creation: Experience the Power of Our Vision in Action."
        }
      />
      {!isMobile && !isTablet ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}
      <Faqs strapiData={faqs} pageName="Javascript Development Company" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "java-script" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default javascript
