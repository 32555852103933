// extracted by mini-css-extract-plugin
export var SliderWidth = "ChooseUs-module--SliderWidth--b241e";
export var arrowImg = "ChooseUs-module--arrowImg--4679b";
export var card = "ChooseUs-module--card--75c55";
export var cardWrapper = "ChooseUs-module--cardWrapper--a057f";
export var cards = "ChooseUs-module--cards--1c54b";
export var description = "ChooseUs-module--description--6cc92";
export var fineBg = "ChooseUs-module--fineBg--33c6b";
export var heading = "ChooseUs-module--heading--1fb98";
export var headingPremium = "ChooseUs-module--headingPremium--846bd";
export var iconContainer = "ChooseUs-module--iconContainer--520e4";
export var iconContainerLeft = "ChooseUs-module--iconContainerLeft--6c9ee";
export var portfolioArrowIcon = "ChooseUs-module--portfolioArrowIcon--ea907";
export var portfolioArrowIconCover = "ChooseUs-module--portfolioArrowIconCover--b58e9";
export var portfolioArrowRightIconCover = "ChooseUs-module--portfolioArrowRightIconCover--17e60";
export var premiumImg = "ChooseUs-module--premiumImg--f4241";
export var providingImg = "ChooseUs-module--providingImg--9cd21";
export var teamButton = "ChooseUs-module--teamButton--08ee9";
export var teamWork = "ChooseUs-module--teamWork--f10a0";