import React from "react"
import { Link } from "gatsby"
import for1 from "../../images/it-staff/for.svg"
import chooseDots from "../../images/javascript/chooseDots.svg"
import * as styles from "./BuildingSoftware2.module.scss"

const BuildingSoftware2 = ({ strapiData }) => {
  return (
    <div className={styles.Ideation}>
      <img
        className={styles.for1}
        src={for1}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.chooseDots}
        src={chooseDots}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <h2
        className={styles.heading}
        dangerouslySetInnerHTML={{ __html: strapiData?.title }}
      />
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: strapiData?.subTitle,
        }}
      />
      <div className={styles.cardsData}>
        <div>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <div className={styles.cards} key={i}>
                <div className={styles.nmbrImg}>
                  <img
                    src={e?.image2 && e?.image2[0]?.localFile?.publicURL}
                    alt={e?.image2 && e?.image2[0]?.alternativeText}
                    decoding="async"
                    loading="lazy"
                  />
                  <img
                    src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                    alt={e?.image1 && e?.image1[0]?.alternativeText}
                    decoding="async"
                    loading="lazy"
                  />
                </div>
                <div
                  className={styles.step}
                  dangerouslySetInnerHTML={{
                    __html: e?.description?.description,
                  }}
                />
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            ))}
        </div>
      </div>

      {strapiData?.buttons[0] && (
        <Link
          to={strapiData?.buttons[0]?.url}
          className={styles.btn_white1_border}
        >
          {strapiData?.buttons[0]?.title}
        </Link>
      )}
    </div>
  )
}

export default BuildingSoftware2
