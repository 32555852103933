// extracted by mini-css-extract-plugin
export var Ideation = "BuildingSoftware2-module--Ideation--bf5dc";
export var btn_white1_border = "BuildingSoftware2-module--btn_white1_border--056d9";
export var cards = "BuildingSoftware2-module--cards--f33c5";
export var cardsData = "BuildingSoftware2-module--cardsData--2afd6";
export var chooseDots = "BuildingSoftware2-module--chooseDots--1ab37";
export var description = "BuildingSoftware2-module--description--d7ab2";
export var for1 = "BuildingSoftware2-module--for1--6ec34";
export var heading = "BuildingSoftware2-module--heading--db80b";
export var nmbrImg = "BuildingSoftware2-module--nmbrImg--52bc4";
export var step = "BuildingSoftware2-module--step--24e1c";