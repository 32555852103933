// extracted by mini-css-extract-plugin
export var Frame = "BuildingSoftware-module--Frame--6f969";
export var Ideation = "BuildingSoftware-module--Ideation--e3e16";
export var arrow2 = "BuildingSoftware-module--arrow2--822f3";
export var btn_white1_border = "BuildingSoftware-module--btn_white1_border--9195c";
export var cards = "BuildingSoftware-module--cards--ab7f3";
export var cards2 = "BuildingSoftware-module--cards2--66518";
export var cardsData = "BuildingSoftware-module--cardsData--17acb";
export var cir = "BuildingSoftware-module--cir--001b3";
export var circle = "BuildingSoftware-module--circle--43d4e";
export var closeUp = "BuildingSoftware-module--closeUp--81403";
export var dec = "BuildingSoftware-module--dec--788d3";
export var dots = "BuildingSoftware-module--dots--99c88";
export var dots2 = "BuildingSoftware-module--dots2--e270c";
export var heading = "BuildingSoftware-module--heading--2fc82";
export var keyBtn = "BuildingSoftware-module--keyBtn--f5a6a";
export var leftText = "BuildingSoftware-module--leftText--f3644";
export var mobBg = "BuildingSoftware-module--mobBg--866e2";
export var number = "BuildingSoftware-module--number--0cf46";
export var number2 = "BuildingSoftware-module--number2--db0b2";
export var rec = "BuildingSoftware-module--rec--5e77e";
export var rightText = "BuildingSoftware-module--rightText--a872f";
export var step = "BuildingSoftware-module--step--8181c";