// extracted by mini-css-extract-plugin
export var Group = "PowerUp-module--Group--3ba60";
export var arrowImg = "PowerUp-module--arrowImg--215fb";
export var card = "PowerUp-module--card--b1eb4";
export var cardWrapper = "PowerUp-module--cardWrapper--38abe";
export var choseImg = "PowerUp-module--choseImg--de46a";
export var cir = "PowerUp-module--cir--74c6d";
export var description = "PowerUp-module--description--1beae";
export var dots1 = "PowerUp-module--dots1--a9a8e";
export var dots2 = "PowerUp-module--dots2--9f0b1";
export var dots3 = "PowerUp-module--dots3--dac47";
export var dots4 = "PowerUp-module--dots4--ba827";
export var for1 = "PowerUp-module--for1--b8743";
export var heading = "PowerUp-module--heading--8d61b";
export var keyBtn = "PowerUp-module--keyBtn--9edb3";
export var powerRec = "PowerUp-module--powerRec--b63ed";
export var staffRec = "PowerUp-module--staffRec--a2546";
export var subservice = "PowerUp-module--subservice--cd930";
export var teamBtn = "PowerUp-module--teamBtn--1baa3";