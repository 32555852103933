import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dots2 from "../../images/it-staff/Group4.png"
import our from "../../images/IOS/0ur.png"
import Group2 from "../../images/it-staff/Group2.png"
import cir from "../../images/custom-web-application/cir.svg"
import for1 from "../../images/it-staff/for.svg"
import powerRec from "../../images/javascript/powerRec.svg"
import stafff from "../../images/IOS/ARROW.png"
import * as styles from "./PowerUp.module.scss"

const PowerUp = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <div className={styles.for1}>
        <img src={for1} decoding="async" loading="lazy" alt="background" />
      </div>
      <div className={styles.cir}>
        <img decoding="async" loading="lazy" src={cir} alt="circle" />
      </div>
      <div className={styles.dots1}>
        <img decoding="async" loading="lazy" src={dots2} alt="dots" />
      </div>

      <div className={styles.Group}>
        <img decoding="async" loading="lazy" src={Group2} alt="dots" />
      </div>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards.map((e, i) => (
            <Col lg={6} xs={12} key={i}>
              <div className={styles.card}>
                <img
                  src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                  alt={e?.image1 && e?.image1[0]?.alternativeText}
                  decoding="async"
                  loading="lazy"
                />
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
          <div className={styles.dots2}>
            <img decoding="async" loading="lazy" src={dots2} alt="dots" />
          </div>
        </Row>
        <div className={styles.dots3}>
          <img decoding="async" loading="lazy" src={our} alt="dots" />
        </div>
        <div className={styles.staffRec}>
          <img decoding="async" loading="lazy" src={stafff} alt="staff" />
        </div>
        <img
          className={styles.powerRec}
          decoding="async"
          loading="lazy"
          src={powerRec}
          alt="staff"
        />
      </Container>
    </div>
  )
}

export default PowerUp
