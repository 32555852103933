import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Unlock.module.scss"

const Unlock = ({ strapiData }) => {
  return (
    <div className={`${styles.Unlock}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        {strapiData?.buttons[0] && (
          <Link
            to={strapiData?.buttons[0]?.url}
            className={styles.btn_white2_border}
          >
            {strapiData?.buttons[0]?.title}
          </Link>
        )}
      </Container>
    </div>
  )
}

export default Unlock
